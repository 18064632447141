import React, { Fragment, useState, useRef } from 'react'
import { jsonToFormData } from '../Basicos/funcoes';
import { Nav, TabContent, TabPane, Button, Form, FormGroup, Col, Row } from 'reactstrap';
import ModalHoc from "../Hoc/modalHoc"
import { Estruturas, NavItemCustom, Loader } from "../Basicos/index"
import { axiosApi } from '../Basicos/axiosInstances'
import { connect } from 'react-redux'
import * as actionsRedux from '../Store/Actions/index'
import ChaveamentoDeModal from './ChaveamentoDeModal';

const Modal = (props) => {
    const input = [];
    const [submit, setSubmit] = useState(false);
    const [loader, setLoader] = useState(false);
    const formulario = useRef({});

    const [ativarModalSecundario, toggleModalSecundario] = useState(false);
    const [estruturaModalSecundario, setEstrutura] = useState();

    const [activeTab, setActiveTab] = useState(0);
    const {
        titulo,
        semSalvar,
        navs,
        blocos,
        estrutura,
        metodoRequisicao,
        requisicaoURL,
        contentType = 'application/json'
    } = props.data.modal;

    const buscarModalSecundario = async ({ url, metodo, params }) => {
        setLoader(true);
        try {
            const response = await axiosApi({
                method: metodo ? metodo : metodoRequisicao,
                url: url ? url : requisicaoURL,
                params: params
            });
            // console.log(response[0])
            // if (response[0] == 'professores')
            //     setEstrutura(response.data[2]);
            // else
            setEstrutura(response.data);
            toggleModalSecundario(true);
        } catch (error) {
            // console.log(error)
            props.ativar("receber", "erro");
        } finally {
            setLoader(false);
        }
    }

    const gerarEstruturaRef = (indiceNav = undefined) => {
        const estruturaRef = React.createRef();
        input.push({ indiceNav, estruturaRef });
        return estruturaRef;
    };

    const onChangeEstrutura = (formularioEstrutura) => {
        formulario.current = { ...formulario.current, ...formularioEstrutura };
    };

    const submitOnClick = async () => {
        const tipoDeAcao = metodoRequisicao === "put" ? "editar" : "adicionar";
        let formularioInvalido = false;
        let dados = {};
        try {
            for (let i = 0; i < input.length; i++) {
                const { state, formulario } = input[i].estruturaRef.current;
                console.log(state, formulario)
                if (state.incompleto) {
                    formularioInvalido = true;
                    if (input[i].indiceNav !== undefined) {
                        setActiveTab(input[i].indiceNav);
                    }
                    break;
                }
                Object.assign(dados, formulario);
            }
            if (!formularioInvalido) {
                setLoader(true);
                try {
                    const response = await axiosApi({
                        method: contentType === 'multipart/form-data' ? 'POST' : metodoRequisicao,
                        url: requisicaoURL,
                        headers: { 'Content-Type': contentType },
                        data: contentType === 'multipart/form-data' ? jsonToFormData(dados, metodoRequisicao) : dados
                    });

                    props.ativar(tipoDeAcao, "sucesso");

                    if (props[tipoDeAcao]) props[tipoDeAcao](response.data.model);
                    props.toggle()
                } catch (error) {
                    if (error.response && error.response.data.erro) {
                        props.ativar(error.response.status, error.response.data.erro)
                    } else {
                        props.ativar(tipoDeAcao, "erro");
                    }
                    props.toggle();
                }
            }
            setSubmit(true);
        } catch (error) {
            console.log(error);
        }
    }

    const gerarBlocos = (estruturas, indiceNav) => {
        return estruturas.map((elemento, indice) => {
            return <Estruturas
                estrutura={elemento.estrutura}
                buscarModalSecundario={buscarModalSecundario}
                ref={gerarEstruturaRef(indiceNav)}
                onChange={onChangeEstrutura}
                formularioGeral={formulario.current}
                tipo={"forms"}
                submit={submit}
                key={indice}
            />
        });
    }

    return (
        <Fragment>
            <Row noGutters className="menu-modal-formal">
                <Col sm={12} className="align-content-middle mb-2 mt-2">
                    <h4><b>{titulo}</b></h4>
                </Col>
                {navs && (
                    <Col sm={12} className="align-content-middle">
                        <Nav tabs className="">
                            {navs.map((elemento, indice) => {
                                return <NavItemCustom
                                    activeTab={activeTab}
                                    onClick={() => setActiveTab(indice)}
                                    descricao={elemento.label}
                                    id={indice}
                                    key={indice}
                                />
                            })}
                        </Nav>
                    </Col>
                )}
            </Row>
            <Form className="text-secondary">
                {navs && (
                    <Fragment>
                        {navs.map((elemento, indice) => {
                            return <TabContent activeTab={activeTab} key={indice}>
                                <TabPane tabId={indice}>
                                    <FormGroup row className="p-4">
                                        {gerarBlocos(elemento.blocos, indice)}
                                    </FormGroup>
                                </TabPane>
                            </TabContent>
                        })}
                    </Fragment>
                )}

                {blocos && (
                    <FormGroup row className="p-4">
                        {gerarBlocos(blocos)}
                    </FormGroup>
                )}

                {estrutura && (
                    <FormGroup row style={{ marginTop: 10 }} className="p-4">
                        <Estruturas
                            estrutura={estrutura}
                            buscarModalSecundario={buscarModalSecundario}
                            ref={gerarEstruturaRef()}
                            onChange={onChangeEstrutura}
                            formularioGeral={formulario.current}
                            tipo={"forms"}
                            submit={submit}
                        />
                    </FormGroup>
                )}
            </Form>
            <Col sm={12} className="align-content-middle mb-3 p-0">
                <Button onClick={props.toggle} className="float-right btn-transparente">{semSalvar ? "Sair" : "Cancelar"}</Button>
                {!semSalvar && <Button onClick={submitOnClick} className="float-right">Salvar</Button>}
            </Col>
            {loader && <Loader overlay />}

            <ChaveamentoDeModal
                boolModal={ativarModalSecundario}
                toggle={() => toggleModalSecundario(!ativarModalSecundario)}
                estrutura={estruturaModalSecundario}
            />

        </Fragment>
    );
}



const mapDispatchToProps = (dispatch) => {
    return {
        ativar: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao)),
        desativar: () => dispatch(actionsRedux.desativarAlert())
    }
}

const ModalForm = ModalHoc(React.memo(Modal))
export default connect(null, mapDispatchToProps)(ModalForm)


