import React, { Fragment, useEffect, useState } from 'react';
import { Col, Button } from "reactstrap";
import { Tabela, Loader, Filtros } from "../../Basicos/index";
import CardHoc from "../../Hoc/cardHoc";
import MenuTable from "./../../Menu/menuTable";
import { connect } from 'react-redux';
import * as actionsRedux from '../../Store/Actions/index';
import ChaveamentoDeModal from '../../Modal/ChaveamentoDeModal';
import { convertToSlug } from '../../Basicos/funcoes';
import Axios from 'axios';

function montarColunas(campos, { editar, deletar }, removerOnClick, editarOnClick) {
    const colunas = campos.estrutura.map((elemento) => {
        switch (elemento.name) {
            case "cor":
                return {
                    Header: elemento.label,
                    Cell: ({ row }) => (
                        <div style={{ background: row.original.cor, width: -1, height: 32, borderRadius: 100 }} />)
                };

            default:
                return {
                    Header: elemento.label,
                    accessor: elemento.name,
                    className: `tabela_colunm_${convertToSlug(elemento.label)}`
                }
        }
    });

    if (editar || deletar) {
        colunas.push({
            Cell: ({ row }) => (
                <MenuTable
                    iconEdit={editar}
                    iconRemover={deletar}
                    removerOnClick={() => removerOnClick(row.original.id)}
                    editarOnClick={() => editarOnClick(row.original.id)}
                />
            ),
            resizable: false,
            accessor: "acoes",
            style: { width: 64 }
        });
    }
    return colunas;
}

function CardCrud({
    filtro,
    adicionarOnClick,
    alterarOnClick,
    removerOnClick,
    editarOnClick,
    novoOnClick,
    pesquisaGlobal,
    atualizarDadosRemotamente,
    buscarValoresPaginacao,
    estadoModal,
    desativarModal,
    estruturaModal,
    subFuncionalidade: {
        url,
        async,
        dados,
        filtros,
        loader,
        descricao,
        campos,
        acoes
    },
}) {

    const loaderStatus = !dados || loader;
    const colunas = React.useMemo(() => montarColunas(campos, acoes, removerOnClick, alterarOnClick), [])

    const [dadosFiltrados, setDadosFiltrados] = useState([]);
    const [valoresFiltro, setValoresFiltro] = useState({
        curso_id: undefined,
        semestre_id: undefined,
        tema_id: undefined,
        tipo_de_atividade_id: undefined,
        status: true
    })

    useEffect(() => atualizarDadosRemotamente(), []);
    useEffect(() => {
        if (dados) {
            setDadosFiltrados(() => [])
            setDadosFiltrados(() => dados)
        }
    }, [dados])

    useEffect(() => {
        if (dados &&
            valoresFiltro.curso_id != undefined &&
            valoresFiltro.semestre_id != undefined &&
            valoresFiltro.tema_id != undefined &&
            valoresFiltro.tipo_de_atividade_id != undefined
        ) {
            const filtraTipoAtividade = dados.filter(dado => (
                dado.curso_id == valoresFiltro.curso_id
                && dado.semestre_id == valoresFiltro.semestre_id
                && dado.tema_id == valoresFiltro.tema_id
                && dado.tipo_de_atividade_id == valoresFiltro.tipo_de_atividade_id))
            setDadosFiltrados(filtraTipoAtividade)
        }
    }, [valoresFiltro, dados]);

    // Handler para fazer a requisição de atualização do status
    const handleUpdateStatus = async () => {
        try {
            const response = await Axios.post('/rooms/update-status'); // Envia a requisição
            console.log('Atualização de status bem-sucedida:', response.data);
            atualizarDadosRemotamente(); // Atualiza os dados após a atualização de status
        } catch (error) {
            console.error('Erro ao atualizar status:', error);
        }
    };

    return (
        <Fragment>
            {
                filtro ?
                    <Filtros novoOnClick={novoOnClick}
                        filtros={filtros}
                        dados={dados}
                        dadosFiltrados={dadosFiltrados}
                        setDadosFiltrados={setDadosFiltrados}
                        valoresFiltro={valoresFiltro}
                        setValoresFiltro={setValoresFiltro}
                    />
                    :
                    <Fragment>
                        <Col xs={12} sm={12} md={5} lg={6} xl={7} className="d-flex mb-3 header-legenda text-header">
                            <h3><strong> {descricao.toUpperCase()} </strong></h3>
                        </Col>
                        <Col xs={12} sm={12} md={7} lg={6} xl={5} className="header-acoes mb-3">
                            <div id="pesquisar-wrap"></div>
                            {acoes.criar && <Button onClick={novoOnClick}>Novo</Button>}
                            {url == '/locais' && <Button className="ml-3" onClick={handleUpdateStatus}>Atualizar</Button>}
                        </Col>
                    </Fragment>
            }

            <Col sm={12} md={12}>
                {!loaderStatus &&
                    <Tabela
                        pesquisarGlobal={pesquisaGlobal ?? true}
                        columns={colunas}
                        data={dadosFiltrados}
                        buscarValoresPaginacao={buscarValoresPaginacao}
                        async={async}
                    />
                }
                {loaderStatus && <Loader />}
            </Col>

            <ChaveamentoDeModal
                boolModal={estadoModal}
                toggle={desativarModal}
                adicionar={adicionarOnClick}
                editar={editarOnClick}
                estrutura={estruturaModal}
            />
        </Fragment>
    );
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        novoOnClick: () => dispatch(actionsRedux.ativarModalSubFuncionalidade(ownProps.funcionalidade, ownProps.indiceSubFuncionalidade, 'adicionar')),
        alterarOnClick: (id) => dispatch(actionsRedux.ativarModalSubFuncionalidade(ownProps.funcionalidade, ownProps.indiceSubFuncionalidade, 'editar', id)),

        removerOnClick: (id) => dispatch(actionsRedux.removerSubFuncionalidade(ownProps.funcionalidade, ownProps.indiceSubFuncionalidade, id)),
        adicionarOnClick: (objeto) => dispatch(actionsRedux.adicionarSubFuncionalidade(ownProps.funcionalidade, ownProps.indiceSubFuncionalidade, objeto)),
        editarOnClick: (objeto) => dispatch(actionsRedux.editarSubFuncionalidade(ownProps.funcionalidade, ownProps.indiceSubFuncionalidade, objeto)),

        desativarModal: () => dispatch(actionsRedux.desativarModalSubFuncionalidade()),
        buscarValoresPaginacao: (url, params) => dispatch(actionsRedux.buscarValoresPaginacao(ownProps.funcionalidade, ownProps.indiceSubFuncionalidade, url, params)),
        atualizarDadosRemotamente: () => dispatch(actionsRedux.atualizarSubFuncionalidade(ownProps.funcionalidade, ownProps.indiceSubFuncionalidade)),
    }
}

const mapStateToProps = (state) => {
    return {
        estruturaModal: state.subFuncionalidades.estruturaModal,
        estadoModal: state.subFuncionalidades.estadoModal,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardHoc(CardCrud))